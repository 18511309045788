import { ReactNode } from 'react';

type IsFetchingBoundaryProps = {
    children?: ReactNode;
    isFetching: boolean;
};

export const IsFetchingBoundary: FC<IsFetchingBoundaryProps> = ({ children, isFetching }) => {
    if (isFetching) {
        return null;
    }

    return <>{children}</>;
};
