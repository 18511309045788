import { useEffect } from 'react';
import { useSessionStore } from 'store/useSessionStore';

export const useFriendlyUrlPage = (fetching: boolean): void => {
    const { updateGeneralState } = useSessionStore((s) => s);

    useEffect(() => {
        updateGeneralState({ isFetchingDataForPage: fetching });
    }, [fetching, updateGeneralState]);
};
